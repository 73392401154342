import React from "react";
import { Container } from "react-bootstrap";

import "./style.css";

const ContactMe = () => {
  return (
    <Container id="contact" className="text-center mb-5">
      <hr />
      <h2>Contact Me</h2>
      Call: <a href="tel:+13027257784">+1 (302) 725-7784</a>
      <br />
      Email: <a href="mailto:bmovlanov@gmail.com">bmovlanov@gmail.com</a>
    </Container>
  );
};

export default ContactMe;
