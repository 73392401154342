import React from "react";
import { CardDeck, Container, Row } from "react-bootstrap";
import ProjectCard from "../ProjectCard";
import projects from "../../assets/projects.json";

const Projects = () => {
  return (
    <Container id="projects" className="text-center">
      <hr />
      <h2>Projects</h2>
      <Row className="align-content-around">
        <CardDeck>
          {projects.map((project, key) => {
            return <ProjectCard key={key} project={project} />;
          })}
        </CardDeck>
      </Row>
    </Container>
  );
};

export default Projects;
