import React from "react";

import About from "./components/About";
import ContactMe from "./components/ContactMe";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Projects from "./components/Projects";

import "./Main.css";

function Main() {
  return (
    <div className="main">
      <Navigation />
      <About />
      <ContactMe />
      <Projects />
      <Footer />
    </div>
  );
}

export default Main;
