import React from "react";
import { Card, Col } from "react-bootstrap";

const ProjectCard = (props) => {
  return (
    <Col className="my-4 text-left" md={6}>
      <Card>
        <Card.Img src={props.project.picture} />
        <Card.Body>
          <Card.Title>{props.project.name}</Card.Title>
          <Card.Text>{props.project.description}</Card.Text>
          <Card.Link href={props.project.github_url}>CODE</Card.Link>
          <Card.Link href={props.project.demo_url}>DEMO</Card.Link>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProjectCard;
