import React from "react";
import "./style.css";
import { Container, Image } from "react-bootstrap";

const About = () => {
  return (
    <Container id="about" className="pt-5 text-center">
      <Image src="img/profile-pic.jpg" rounded height="250px" />
      <div>
        <h1 className="mt-5">Bahram Movlanov</h1>
        <h3>Junior Software Engineer</h3>
        <h5>Denver, CO</h5>
        <hr />
        <h2>About Me</h2>
        <p>
          Detail-oriented, highly motivated Full-Stack Developer. Multi-tasking
          and capable of working under pressure to solve technology-related
          problems using critical thinking and troubleshooting skills. Quick
          learner, always keeping up with the latest technology and concepts and
          their applications to real-world situations.
        </p>
        <h5>
          <a href="docs\Bahram-Movlanov-Resume.pdf">Download Resume</a>
        </h5>
        <div className="social">
          <a href="https://www.linkedin.com/in/bahram-movlanov">
            <i className="fab fa-linkedin mx-3"></i>
          </a>
          <a href="https://github.com/movlan">
            <i className="fab fa-github-square mx-3"></i>
          </a>
          <a href="https://twitter.com/bmovlanov">
            <i className="fab fa-twitter-square mx-3"></i>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default About;
