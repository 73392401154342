import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="footer py-2">
      <Container className="text-center">
        Created by Bahram Movlanov &copy; 2020
      </Container>
    </footer>
  );
};

export default Footer;
